@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

:root {
  --main-bg: #141414;
  --main-block-bg: #212121;
  --secondary-block-bg: #2e2e2e;
  --main-text-color: #ffffff;
  --main-text-color-opacity: rgba(255, 255, 255, 0.2);
  --dark-text-color: #212121;
  --ghost-text-color: #f2f2f2;
  --gray-text-color: #bdbdbd;
  --main-accent-color: #00e5d2;
  --main-accent-color-hover: #2ba99e;
  --error-text-color: #e83052;
  --main-accent-shadow: rgba(0, 229, 210, 0.6);
  --overlay-bg: rgba(0, 0, 0, 0.6);
}

// :root {
//   --main-bg: #dbdbdb;
//   --main-block-bg: #ffffff;
//   --secondary-block-bg: #bebebe;
//   --main-text-color: #000000;
//   --dark-text-color: #ffffff;
//   --ghost-text-color: #8a8a8a;
//   --gray-text-color: #868686;
//   --main-accent-color: #3b31cc;
//   --main-accent-color-hover: #2b33a9;
//   --error-text-color: #e83052;
//   --main-accent-shadow: rgba(0, 61, 229, 0.6);
// }

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  min-width: 320px;
  font-family: 'Geometria', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--main-text-color);
  background-color: var(--main-bg);
}

.visually-hidden:not(:focus):not(:active),
input[type='checkbox'].visually-hidden,
input[type='radio'].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

h1 {
  font-weight: 700;
  font-size: 35px;
  line-height: 52px;
}

h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
}

h3 {
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
}

h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
}

h5 {
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
}

h6 {
  font-weight: 700;
  font-size: 15px;
  line-height: 21px;
}

p {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

button {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

svg {
  overflow: visible;
}

//скроллы

body * {
  &::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey !important;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3) !important;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4) !important;
  }
}

.main {
  padding-bottom: 140px;
}

.header {
  margin-bottom: 50px;
}
